import {Button, Col, DatePicker, Form, Input, message, Popconfirm, Row, Space} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import FormattedInputNumber from "../../../../../../utils/FormattedInputNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import Api_Maden from "../../../../../../services/Api_Maden";

const MadenCekSenetDetailPage = ({selectedId, close, refreshData}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(selectedId > 0)
            getData()
    }, []);

    const getData = () => {
        setLoading(true)
        Api_Maden.get(`/api/CekSenets/${selectedId}`).then(res => {
            res.data = {...res.data, vadeTarihi: dayjs(res.data.vadeTarihi)}
            form.setFieldsValue(res.data)
            setLoading(false)
        }).catch(err => {
            message.error("Veriler getirilemedi")
            setLoading(false)
        })
    }

    const onFinish = (values) => {
        if(selectedId === 0){
            Api_Maden.post("/api/CekSenets",values).then(res => {
                message.success("Kayıt işlemi başarılı")
                refreshData()
                close()
            }).catch(err => {
                console.log(err)
                message.error("Kayıt işlemi sırasında bir hata oluştu")
            })
        }

        if(selectedId > 0){
            Api_Maden.put("/api/CekSenets",values).then(res => {
                message.success("Güncelleme işlemi başarılı")
                refreshData()
                close()
            }).catch(err => {
                console.log(err)
                message.error("Güncelleme işlemi sırasında bir hata oluştu")
            })
        }
    }

    const onFinishFailed = (errorInfo) => {
        message.error("Formda eksik alanlar var")
    }

    const deleteData = () => {
        Api.delete(`/api/CekSenets/${selectedId}`).then(res => {
            message.success("Silme işlemi başarılı")
            refreshData()
            close()
        }).catch(err => {
            message.error("Silme işlemi başarısız")
        })
    }


    return(
        <>
            {!loading &&
                <Form
                    layout={"horizontal"}
                    form={form}
                    initialValues={{tutar:0}}
                    onFinish={onFinish}
                    labelCol={{span:5}}
                    colon={false}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={[10,10]}>
                        <Col span={24}>
                            <Form.Item hidden={true} name={"id"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Firma Adı"} name={"firmaAdi"} rules={[{ required: true },]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Banka Adı"} name={"bankaAdi"} rules={[{ required: true },]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Vade Tarihi"} name={"vadeTarihi"} rules={[{ required: true },]}>
                                <DatePicker format={"DD.MM.YYYY"}></DatePicker>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Tutar"} name={"tutar"} rules={[{ required: true },]}>
                                <FormattedInputNumber align={"left"} ></FormattedInputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={"Açıklama"} name={"aciklama"}>
                                <Input.TextArea rows={3}></Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={24}  className={"text-align-right"}>
                            <Space>
                                <Button htmlType={"submit"} icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}>Kaydet</Button>
                                {selectedId > 0 &&
                                    <Popconfirm
                                        title="Silme İşlemi"
                                        description="Silme işlemi yapmak istediğinize emin misiniz?"
                                        onConfirm={deleteData}
                                        okText="Evet"
                                        cancelText="Hayır"
                                    >
                                        <Button danger>Sil</Button>
                                    </Popconfirm>
                                }
                            </Space>
                        </Col>

                    </Row>
                </Form>
            }

            {loading && <div>Yükleniyor</div>}


        </>

    )
}

export default MadenCekSenetDetailPage